import { isUndefined } from "lodash";

const atomicRule = (key: string) => {
  return () => key;
};

const valueRule = <T extends any = any>(key: string) => {
  return (v: T) => `${key}:${v}`;
};

const optionalValueRule = <T extends any = any>(key: string) => {
  return (v?: T) => (isUndefined(v) ? key : `${key}:${v}`);
};

const valueRuleWithDefault = <T extends any = any>(key: string, defu: T) => {
  return (v?: T) => `${key}:${v || defu}`;
};

export const Rule = {
  required: atomicRule("required"),
  numeric: valueRule<any>("numeric"),
  confirmed: valueRule<string>("confirmed"),
  email: atomicRule("email"),
  password: atomicRule("max:24|lower|upper|digit|special|min:8"),
  // pricing: optionalValueRule<PricingModelLike>("pricing"),
  pricing: atomicRule("pricing"),
  address: atomicRule("address"),

  // presets
  title: valueRuleWithDefault<number>("max", 72),
  longTitle: valueRuleWithDefault<number>("max", 144),
  short: valueRuleWithDefault<number>("max", 320),
  url: atomicRule("url"),

  group(rules: (string | undefined)[]) {
    return rules.filter((r) => !!r).join("|");
  },

  remote: function (rules: any) {
    const { loading, validate } = useRemoteValidate();
    type Ctx = {
      loading: typeof loading;
      _remote: true;
    };
    const fn = function (this: Ctx, value: any): ReturnType<typeof validate> {
      return validate(value, rules);
    };
    fn.loading = loading;
    fn._remote = true;
    return fn;
  },

  get string() {
    return {
      min: valueRule<number>("min"),
      max: valueRule<number>("max"),
      lower: atomicRule("lower"),
      upper: atomicRule("upper"),
      digit: atomicRule("digit"),
      special: atomicRule("special"),
      size: (v: number) => `min:${v}|max:${v}`,
    };
  },
  get number() {
    return {
      min: valueRule<number>("min_value"),
      max: valueRule<number>("max_value"),
      gtZero: atomicRule("greater_than_zero"),
      positive: atomicRule("positive_value"),
    };
  },
  get date() {
    return {
      before: valueRule<string>("before"),
      after: valueRule<string>("after"),
    };
  },
  get array() {
    return {
      in: valueRule<any[]>("in"),
      notIn: valueRule<any[]>("notIn"),
      min: valueRule<number>("min_count"),
      max: valueRule<number>("max_count"),
    };
  },
};
